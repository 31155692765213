import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from 'views/Loading';

import GenerateGlobalEvent from 'helpers/generateEvents';
import GlobalEvents from 'setts/globalEvents';

let timeout1 = null;
let timeout2 = null;

export default function ScrollToTop() {
  const [isLoader, setLoader] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    // document.body.style.opacity = '0';
    setLoader(true);
    GenerateGlobalEvent(document.body, GlobalEvents.underlay.off);

    timeout1 = setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });

      timeout2 = setTimeout(() => {
        // document.body.style.opacity = '';
        setLoader(false);
      }, 400);
    }, 100);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    }
  }, [pathname]);

  return isLoader && <Loader />;
}
