import React from 'react';
import './styles.scss';

const baseClass = 'ba-btn';

const getAdditional = (type) => {
  switch (type) {
    case 'primary':
      return 'ba-btn--primary';
    case 'outline':
      return 'ba-btn--outline';
    case 'small':
      return 'ba-btn--small';
    default:
      return 'ba-btn--outline';
  }
};

const Button = (props) => {
  const { className, mod, type, children, ...propsRes } = props;

  const classesReady = [baseClass, getAdditional(mod), className]
    .join(' ')
    .trim();

  return (
    <button {...propsRes} type={type || 'button'} className={classesReady}>
      <span>{children}</span>
    </button>
  );
};

export default Button;
