import React from 'react';
import classname from 'classnames';
import { HashLink as NavLink } from 'react-router-hash-link';

import Message from '../Elements/Message';

import styles from './footer.module.scss';
import { APP_THEMES } from 'redux-store/constants';

const FooterMobile = ({
  services,
  phones,
  address,
  openQuickQuestionPopup,
  appTheme,
}) => {
  return (
    <div
      className={classname('ba-wrap', styles.footer, {
        [styles.dark]: appTheme === APP_THEMES.dark,
      })}
    >
      <div className="ba-footer__top ba-footer__row">
        <div className="ba-footer__services ba-footer__row">
          <h3 className="ba-h3">
            <NavLink to={'/services'}>Процедури</NavLink>
          </h3>
          <ul className="ba-footer__services-list ba-footer__row">
            {services.map((i) => (
              <li key={i.title}>
                <NavLink to={'/category-service/' + i.path}>{i.title}</NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="ba-footer__feeds">
          <h3 className="ba-h3">
            <NavLink smooth to={'/testimonials'}>
              Відгуки
            </NavLink>
          </h3>
          <h3 className="ba-h3">
            <NavLink smooth to={'/school'}>
              Навчання
            </NavLink>
          </h3>
        </div>
        <div className="ba-footer__questions">
          <h3 className="ba-h3">
            <NavLink smooth to={'/faq'}>
              Відповіді
            </NavLink>
          </h3>
        </div>
      </div>

      <div className="ba-footer__contacts ba-footer__row">
        <div className="ba-footer__contacts-phones">
          {phones.map((i) => (
            <p key={i}>
              <a href={`tel:${i}`}>{i}</a>
            </p>
          ))}
        </div>
        <div className="ba-footer__contacts-schedule">
          <p>
            <time>10:00–19:00</time>
            <span>Пн-Сб</span>
          </p>
          <p>
            <time>Вихідний</time>
            <span>Нд</span>
          </p>
        </div>
        <a
          href={address.map}
          target="_blank"
          rel="noopener noreferrer"
          className="ba-reg ba-footer__contacts-address"
          dangerouslySetInnerHTML={{
            __html: address.text,
          }}
        ></a>
      </div>

      <Message openQuickQuestionPopup={openQuickQuestionPopup} />

      <div className="ba-footer__bottom">
        <p className="ba-footer__bottom-copyright">
          &#169; Beautyapple, {new Date().getFullYear()}
        </p>

        <NavLink to={'/policy'} className="ba-footer__bottom-policy">
          Політика конфіденційності
        </NavLink>
      </div>

      <p className="ba-footer__creator">
        Сайт розроблений{' '}
        <a
          href="https://crisp-studio.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          crisp
        </a>
      </p>
    </div>
  );
};

export default FooterMobile;
