import variables from 'setts/variables';
import GenerateGlobalEvent from 'helpers/generateEvents';
import GlobalEvents from 'setts/globalEvents';

export const getProducts = () => {
  const products = localStorage.getItem(variables.SELECTED_PRODUCT_IN_STORE);

  return products ? JSON.parse(products) : [];
};

export const getCountProducts = () => getProducts().length;

export const checkProducts = () => getCountProducts() > 0;

export const needUpdateProducts = () =>
  GenerateGlobalEvent(document.body, GlobalEvents.updateProductsInStore);

export const clearProducts = () => {
  localStorage.removeItem(variables.SELECTED_PRODUCT_IN_STORE);
  setTimeout(() => {
    needUpdateProducts();
  }, 100);
};

export const setProducts = (data) => {
  localStorage.setItem(
    variables.SELECTED_PRODUCT_IN_STORE,
    JSON.stringify(data)
  );
  setTimeout(() => {
    needUpdateProducts();
  }, 100);
};
