import React from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import onClickOutside from 'react-onclickoutside';

import styles from './thxnotification.module.scss';
import Icon from 'components/UI/Icons';
import GenerateGlobalEvent from 'helpers/generateEvents';
import GlobalEvents from 'setts/globalEvents';

class ThxMessage extends React.Component {
  constructor(props) {
    super();
    this.close = props.onClosePopup;
    this.wrapScroll = React.createRef();
  }

  componentDidMount() {
    disableBodyScroll(this.wrapScroll.current, { reserveScrollBarGap: true });
    GenerateGlobalEvent(document.body, GlobalEvents.underlay.on);

    setTimeout(() => {
      this.close();
    }, 3000);
  }

  componentWillUnmount() {
    enableBodyScroll(this.wrapScroll.current);
  }

  handleClickOutside() {
    this.close();
    GenerateGlobalEvent(document.body, GlobalEvents.underlay.off);
  }

  render() {
    const { title = 'Дякуємо за заявку', note = 'До зустрічі в Beautyapple!' } =
      this.props;
    return (
      <div className={`${styles.container} ba-center`} ref={this.wrapScroll}>
        <p className={styles.icon}>
          <Icon name="done" />
        </p>
        <p className={styles.title}>
          <b>{title}</b>
        </p>
        <p className={styles.text}>{note}</p>
      </div>
    );
  }
}

export default onClickOutside(ThxMessage);
