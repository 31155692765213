import React, { useMemo } from 'react';
import classnames from 'classnames';
import Icon from 'components/UI/Icons';

import './styles.scss';
import socials from 'mocks/socials';

const socialTypes = ['facebook', 'viber', 'instagram', 'telegram'];

const ContactSocials = ({ type = null }) => {
  const socialsToContact = useMemo(
    () => socials.filter((social) => socialTypes.includes(social.type)),
    []
  );

  return (
    <ul
      className={classnames('ba-socials-list ba-socials-list--contact', {
        'ba-socials-list__light': type === 'light',
        'ba-socials-list__bg-light': type === 'bg-light',
      })}
    >
      {socialsToContact.map((i) => (
        <li key={i.type}>
          <a
            href={i.url}
            aria-label={`Go to ${i.type}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name={`social-${i.type}`} />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default ContactSocials;
