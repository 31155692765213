import { combineReducers } from 'redux';

import web from './web.reducer';
import popup from './popups.reducer';

const rootReducer = combineReducers({
  web,
  popup,
});

export default rootReducer;
