import React, { Component } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';
import SwipeToClose from 'components/Layout/SwipeToClose';
import ContactSocials from 'components/Partials/ContactSocials';

import styles from './contact.module.scss';
import GlobalEvents from 'setts/globalEvents';
import GenerateGlobalEvent from 'helpers/generateEvents';
import Icon from 'components/UI/Icons';
import PhoneNumber from 'components/UI/PhoneNumber';
import Button from 'components/UI/Btn';

import validate from 'helpers/validators';

import { sendFormRequest } from 'api';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toSend: {
        phone: '',
      },
      isValid: {
        phone: false,
      },
      disabled: true,
    };

    this.wrapScroll = React.createRef(null);

    const today = new Date().getDay();

    const currentIndexDay = today === 0 ? 6 : today - 1;
    this.todaySchedule = props.schedule[currentIndexDay];
    this.phones = props.phones;
    this.address = props.address;
    this.close = props.onClose;
    this.device = props.device;
  }

  componentDidMount() {
    if (this.device !== 'desktop') {
      disableBodyScroll(this.wrapScroll.current);
      GenerateGlobalEvent(document.body, GlobalEvents.underlay.on);
      document.body.addEventListener(
        GlobalEvents.underlay.click,
        this.close.bind(this, false)
      );
    }
  }

  componentWillUnmount() {
    enableBodyScroll(this.wrapScroll.current);
  }

  handleClickOutside(e) {
    if (this.device !== 'desktop') {
      GenerateGlobalEvent(document.body, GlobalEvents.underlay.off);
      document.body.removeEventListener(
        GlobalEvents.underlay.click,
        this.close
      );
    }

    if (e && e.target && e.target.closest('.ba-header__phone')) return;
    this.close();
  }

  checkIsEnableSend() {
    const state = !Object.values(this.state.isValid).some((i) => !i);

    this.setState({
      disabled: !state,
    });
  }

  validate({ name, value }) {
    let isValid = false;

    switch (name) {
      case 'phone':
        isValid = validate['phone'](value);
        break;
      default:
        isValid = null;
    }

    if (isValid === null) return;

    this.handlerValidInput({ name, isValid });
  }

  handlerValidInput({ name, isValid }) {
    this.setState(
      {
        isValid: {
          ...this.state.isValid,
          [name]: isValid,
        },
      },
      this.checkIsEnableSend
    );
  }

  handlerInput(e) {
    const { name, value } = e.target;

    this.setState({
      toSend: {
        ...this.state.toSend,
        [name]: value,
      },
    });

    this.validate({ name, value });
  }

  submitForm(e) {
    e.preventDefault();

    const data = this.state.toSend;

    const request = {
      type: 'procedure',
      contact: {
        phone: data.phone.replace(/\D+/g, ''),
      },
      title: 'Заявка з сайту',
      description: 'Отриманий запрос зателефонувати',
    };

    sendFormRequest(request).then(() => {
      this.dontClear = true;
      this.close(true);
    });
  }

  render() {
    return (
      <SwipeToClose
        className={styles.container}
        onSwipe={this.handleClickOutside.bind(this)}
      >
        <div className="ba-wrap" ref={this.wrapScroll}>
          <div className={styles.schedule}>
            <p>
              <time>10:00–19:00</time>&nbsp;<span>Пн-Сб</span>
            </p>
            <p>
              <time>Вихідний</time>&nbsp;<span>Нд</span>
            </p>
          </div>

          <ul>
            {this.phones.map((phone) => (
              <li key={phone} className={styles.phone_item_li}>
                <a href={`tel:${phone}`} className={styles.phone_number}>
                  <Icon name="phone" />
                  <b>{phone}</b>
                </a>
              </li>
            ))}
          </ul>
          <a
            href={this.address.map}
            target="_blank"
            rel="noopener noreferrer"
            className={classnames(styles.address, 'ba-text-light')}
            dangerouslySetInnerHTML={{
              __html: this.address.text,
            }}
          ></a>

          <form
            onSubmit={this.submitForm.bind(this)}
            className={styles.call_form}
            id="callback-head"
          >
            <p>
              <small className="ba-text-light">Зателефонуйте мені</small>
            </p>

            <div className={styles.phone}>
              <PhoneNumber
                value={this.state.toSend.phone}
                inputClasses={styles.input}
                autoComplete="tel"
                onChange={this.handlerInput.bind(this)}
              />
            </div>

            <Button
              mod="primary"
              type="submit"
              className={styles.submit}
              disabled={this.state.disabled}
            >
              <span>Зателефонуйте мені</span>
            </Button>
          </form>

          <ContactSocials />
        </div>

        <button
          type="button"
          onClick={this.handleClickOutside.bind(this)}
          className={styles.close}
        >
          <Icon name="close" />
        </button>
      </SwipeToClose>
    );
  }
}

export default onClickOutside(Contact);
