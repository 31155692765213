import { popups } from '../constants';

const initialState = {
  opened: '',
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case popups.SET_OPENED_POPUP:
      return {
        ...state,
        ...{
          opened: action.payload,
        },
      };
    default:
      return state;
  }
};

export default servicesReducer;
