import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Icon from '../Icons';
import './styles.scss';
import { getCountProducts } from 'helpers/useProductsInLocalStore';
import GlobalEvents from 'setts/globalEvents';
import declOfNum from 'helpers/declOfNum';

const baseClass = 'ba-cart-float';

const getAdditional = (type) => {
  switch (type) {
    case 'default':
      return 'ba-cart-float--default';
    case 'full':
      return 'ba-cart-float--full';
    case 'empty':
      return 'ba-cart-float--empty';
    default:
      return 'ba-cart-float--default';
  }
};

const BtnClose = (props) => {
  const distance = 1500;
  const [isInBasket, setInBusket] = useState(getCountProducts());

  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(window.scrollY > distance);
    };

    onScroll();
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  useEffect(() => {
    const updateStatus = () => {
      setInBusket(getCountProducts());
    };

    document.body.addEventListener(
      GlobalEvents.updateProductsInStore,
      updateStatus
    );

    return () => {
      document.body.removeEventListener(
        GlobalEvents.updateProductsInStore,
        updateStatus
      );
    };
  });

  const { className, svg } = props;

  const result = ' ' + declOfNum(isInBasket, ['товар', 'товара', 'товаров']);

  const mod = scrollTop ? 'default' : 'full';
  const classesReady = [
    baseClass,
    getAdditional(isInBasket > 0 ? mod : 'empty'),
    className,
  ]
    .join(' ')
    .trim();

  return (
    <Link
      to={isInBasket > 0 ? '/basket' : '/beauty-shop'}
      // { ...propsRes }
      className={classesReady}
    >
      <p className={classnames('ba-cart-float--inner')}>
        <Icon name={svg} />
        {isInBasket > 0 && isInBasket}&#160;
      </p>

      {isInBasket > 0 && (
        <p
          className={classnames(
            'ba-cart-float--result',
            scrollTop ? '' : 'show'
          )}
        >
          {result}
        </p>
      )}
    </Link>
  );
};

export default BtnClose;
