const isIOS = () => {
  const iOS =
    navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  // const iOS = navigator.platform && (/iPad|iPhone|iPod/.test(navigator.platform));

  iOS && document.body.classList.add('iOS');

  return iOS;
};

export default isIOS();
