import React from 'react';
import classnames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import GenerateGlobalEvent from 'helpers/generateEvents';
import GlobalEvents from 'setts/globalEvents';

import MobileFooter from './Mobile';
import TabletFooter from './Tablet';
import DesktopFooter from './Desktop';

import { APP_THEMES } from 'redux-store/constants';
import devicesName from 'setts/devices';

import phones from 'mocks/phones';
import { address } from 'mocks/common-info';

import './styles.scss';

const Footer = ({ device, services, ...props }) => {
  const openQuickQuestionPopup = () => {
    GenerateGlobalEvent(document.body, GlobalEvents.quickQuestion);
  };

  const getView = (device) => {
    let CurFooter = null;

    switch (device) {
      case devicesName.mobile:
        CurFooter = MobileFooter;
        break;
      case devicesName.tablet:
        CurFooter = TabletFooter;
        break;
      case devicesName.desktop:
        CurFooter = DesktopFooter;
        break;
      default:
        CurFooter = MobileFooter;
        break;
    }

    return (
      <CurFooter
        {...props}
        services={services}
        phones={phones}
        address={address}
        openQuickQuestionPopup={openQuickQuestionPopup}
      />
    );
  };

  return (
    <footer
      className={classnames('ba-footer', {
        'ba-footer--dark': props.appTheme === APP_THEMES.dark,
      })}
    >
      {getView(device)}
    </footer>
  );
};

const mapStateToProps = ({ web }) => ({
  services: web.servicesList.sort((a, b) =>
    a.title.localeCompare('Додаткові послуги') ? 1 : -1
  ),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
