import React from 'react';
import { useSwipeable } from 'react-swipeable';

const SwipeToClose = ({ onSwipe, children, ...propses }) => {
  const handlers = useSwipeable({
    onSwipedRight: onSwipe,
    delta: 50,
  });

  return (
    <div {...propses} {...handlers}>
      {children}
    </div>
  );
};

export default SwipeToClose;
