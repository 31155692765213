export default [
  {
    type: 'facebook',
    url: 'https://m.me/center.beautyapple',
  },
  {
    type: 'viber',
    url: 'viber://pa?chatURI=centerbeautyapple',
  },
  {
    type: 'tik-tok',
    url: 'https://www.tiktok.com/@center.beautyapple',
  },
  {
    type: 'telegram',
    url: 'https://t.me/centerbeautyapplebot',
  },
  {
    type: 'instagram',
    url: 'https://www.instagram.com/center.beautyapple/',
  },
  {
    type: 'youtube',
    url: 'https://www.youtube.com/channel/UChkfU-rjZhH2Zq3_LyBibJw?view_as=subscriber',
  },
];
