import React from 'react';
import onClickOutside from 'react-onclickoutside';
// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';

import SwipeToClose from 'components/Layout/SwipeToClose';

import GenerateGlobalEvent from 'helpers/generateEvents';

import styles from './quickRegister.module.scss';
import Switcher from 'components/UI/Switcher';
import Input from 'components/UI/Input';
import Button from 'components/UI/Btn';
import Icon from 'components/UI/Icons';
import GlobalEvents from 'setts/globalEvents';

import PhoneNumber from 'components/UI/PhoneNumber';

import validate from 'helpers/validators';

import { maxLengthNameInput } from 'setts/formats';
import ContactSocials from 'components/Partials/ContactSocials';

const types = [
  {
    label: 'Консультація',
    value: 'Консультація',
  },
  {
    label: 'Процедура',
    value: 'Процедура',
  },
];

class QuickRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toSend: {
        procedure: types[0].value,
        name: '',
        phone: '',
      },
      isValid: {
        phone: false,
      },
      disabled: true,
    };

    this.close = this.props.onClosePopup;
  }

  componentDidMount() {
    GenerateGlobalEvent(document.body, GlobalEvents.underlay.on);
  }

  handleClickOutside() {
    this.close();
    GenerateGlobalEvent(document.body, GlobalEvents.underlay.off);
  }

  checkIsEnableSend() {
    const state = !Object.values(this.state.isValid).some((i) => !i);

    this.setState({
      disabled: !state,
    });
  }

  validate({ name, value }) {
    let isValid = false;

    switch (name) {
      case 'name':
        isValid = validate['required'](value);
        break;
      case 'phone':
        isValid = validate['phone'](value);
        break;
      default:
        isValid = null;
    }

    if (isValid === null) return;

    this.handlerValidInput({ name, isValid });
  }

  handlerSwitcher({ value }) {
    this.setState(
      {
        toSend: {
          ...this.state.toSend,
          procedure: value,
        },
      },
      this.checkIsEnableSend
    );
  }

  handlerValidInput({ name, isValid }) {
    this.setState(
      {
        isValid: {
          ...this.state.isValid,
          [name]: isValid,
        },
      },
      this.checkIsEnableSend
    );
  }

  handlerInput(e) {
    const { name, value } = e.target;

    this.setState({
      toSend: {
        ...this.state.toSend,
        [name]: value,
      },
    });

    this.validate({ name, value });
  }

  submitForm(e) {
    e.preventDefault();

    this.props.onSendForm(this.state.toSend);

    ReactGA.event({
      category: 'form',
      action: 'indexappointment',
    });

    // if (window.fbq) window.fbq('track', 'Lead');

    this.props.onClosePopup();
  }

  render() {
    return (
      <SwipeToClose
        className={styles.container}
        onSwipe={this.handleClickOutside.bind(this)}
      >
        {/* <div
        className={ styles.container }
      > */}
        <div className="ba-wrap">
          <button
            type="button"
            onClick={this.handleClickOutside.bind(this)}
            className={styles.close}
          >
            <Icon name="close" />
          </button>
          <form id="indexappointment" onSubmit={this.submitForm.bind(this)}>
            <div className={styles.switcher_wrap}>
              <Switcher
                name="procedure"
                className={styles.switcher}
                onChange={this.handlerInput.bind(this)}
                value={this.state.toSend.procedure}
                keys={types}
              />
            </div>

            <div className={styles.input_wrap}>
              <Input
                placeholder="Ім'я"
                name="name"
                autoComplete="given-name"
                className={styles.input}
                maxLength={maxLengthNameInput}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(
                    /[^a-zA-Z\u00C0-\u017F\u0400-\u04FF]+$/,
                    ''
                  );
                  this.handlerInput.call(this, e);
                }}
              />
            </div>

            <div className={styles.input_wrap}>
              <PhoneNumber
                value={this.state.toSend.phone}
                autoComplete="tel"
                inputClasses={styles.input}
                onChange={this.handlerInput.bind(this)}
              />
            </div>

            <p className={`${styles.note} ba-small ba-center `}>
              Ми зателефонуємо вам протягом години та уточнимо деталі вашого візиту
            </p>

            <Button
              mod="primary"
              type="submit"
              className={styles.submit}
              disabled={this.state.disabled}
            >
              <span>Залишити заявку</span>
              <Icon name="arrow-right" />
            </Button>
          </form>

          <ContactSocials />
        </div>
        {/* </div> */}
      </SwipeToClose>
    );
  }
}

export default onClickOutside(QuickRegister);
