import React, { Suspense } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import './styles.scss';

import Contact from './Elements/Contact';

import GlobalEvents from 'setts/globalEvents';
import GenerateGlobalEvent from 'helpers/generateEvents';
import devicesName from 'setts/devices';

import phones from 'mocks/phones';
import schedule from 'mocks/schedule';
import { address } from 'mocks/common-info';
import { APP_THEMES } from 'redux-store/constants';

const MobileHeader = React.lazy(() => import('./Mobile'));
const DesktopHeader = React.lazy(() => import('./Desktop'));

export default class Navbar extends React.Component {
  state = {
    isNeedThx: false,
    isOpenContact: false,
  };

  toggleContact(e) {
    this.setState({ isOpenContact: !this.state.isOpenContact });
  }

  closeContact(isNeedThx = false) {
    this.setState({
      ...this.state.header,
      isNeedThx: isNeedThx,
      isOpenContact: false,
    });
  }

  getView(device) {
    const { ...props } = this.props;
    const { isOpenContact } = this.state;
    let CurHeader = null;

    switch (device) {
      case devicesName.mobile:
      case devicesName.tablet:
        CurHeader = MobileHeader;
        props.isOpenContact = isOpenContact;
        break;
      case devicesName.desktop:
        CurHeader = DesktopHeader;
        props.phones = phones;
        break;
      default:
        CurHeader = MobileHeader;
        props.isOpenContact = isOpenContact;
    }

    return (
      <CurHeader {...props} toggleContact={this.toggleContact.bind(this)} />
    );
  }

  render() {
    const { device, appTheme } = this.props;
    const { isOpenContact, isNeedThx } = this.state;

    return (
      <header
        className={classnames('ba-header', {
          'ba-header--dark': appTheme === APP_THEMES.dark,
        })}
      >
        <Suspense fallback={<div></div>}>{this.getView(device)}</Suspense>

        <CSSTransition
          in={isOpenContact}
          classNames="slide-down"
          timeout={{
            enter: 300,
            exit: 200,
          }}
          onExited={() =>
            isNeedThx &&
            GenerateGlobalEvent(document.body, GlobalEvents.thxNotification)
          }
          unmountOnExit
        >
          <Contact
            phones={phones}
            schedule={schedule}
            address={address}
            device={device}
            onClose={this.closeContact.bind(this)}
          />
        </CSSTransition>
      </header>
    );
  }
}
