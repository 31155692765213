import axios from 'axios';
import * as school from './school';

import urls from '../setts/urls';

export default {
  ...school,
};

export const getServicesList = () => {
  const { method, url } = urls.services;

  return axios({
    url,
    method,
  });
};

export const getNewsList = () => {
  const { method, url } = urls.news;

  return axios({
    url,
    method,
  });
};

export const getFeedsList = () => {
  const { method, url } = urls.feeds;

  return axios({
    url,
    method,
  });
};

export const getFAQList = () => {
  const { method, url } = urls.faq;

  return axios({
    url,
    method,
  });
};

export const getSpecialistsList = () => {
  const { method, url } = urls.specialists;

  return axios({
    url,
    method,
  });
};

export const getProducts = () => {
  const { method, url } = urls.products;

  return axios({
    url,
    method,
  });
};

export const getCountContacts = () => {
  const { method, url } = urls.contacts;

  return axios({
    url,
    method,
  });
};

export const sendFormRequest = (data) => {
  const { method, url } = urls.sendForm;

  return axios({
    url,
    method,
    data,
  });
};

export const sendProductRequest = (data) => {
  const { method, url } = urls.sendProduct;

  return axios({
    url,
    method,
    data,
  });
};

export const sendFeedRequest = (data) => {
  const { method, url } = urls.sendFeed;

  return axios({
    url,
    method,
    data,
  });
};

export const getPostsRequest = (data) => {
  const { method, url } = urls.getPosts;

  return axios({
    url,
    method,
    data,
  });
};

export const sendFAQStatistic = ({ id, ...data }) => {
  const { method, url } = urls.sendFAQStatistic(id);

  return axios({
    url,
    method,
    data,
  });
};

export const getMetatagsList = () => {
  const { method, url } = urls.getMetatags;

  return axios({
    url,
    method,
  });

  // return fetch('/temp/metatags.json').then(res => res.json())
};
