import React, { useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'redux-store/actions';
import classnames from 'classnames';
import PwaInstallPopupIOS from 'react-pwa-install-ios';

import ScrollToTop from 'components/Layout/ScrollToTop';
import GoogleAnalitics from './components/Layout/GoogleAnalitics';
import CartBtnFloat from 'components/UI/BtnCartFloat';
import Routers from './routers';
import 'assets/scss/transitions.scss';

import Navbar from './components/Layout/Navbar';
import Footer from './components/Layout/Footer';
import Popups from './components/Layout/Popups';

import Loading from './views/Loading';

import 'helpers/addressBarChecked';

import devicesName from 'setts/devices';

const statesDevice = [
  {
    width: 567,
    state: devicesName.mobile,
  },
  {
    width: 990,
    state: devicesName.tablet,
  },
];

const LayoutWrapper = ({ children }) => {
  const location = useLocation();
  const [isHiddenLayout, setHiddenLayout] = useState(false);

  useEffect(() => {
    setHiddenLayout(location.pathname.includes('feedback'));
  }, [location.pathname]);

  return !isHiddenLayout && children;
};

const App = () => {
  const [device, setDevice] = useState();
  const dispatch = useDispatch();
  const { appTheme } = useSelector(({ web }) => web);

  const getDevice = useCallback(() => {
    let device = statesDevice.find((i) => window.innerWidth <= i.width);

    device = !device ? devicesName.desktop : device.state;

    setDevice(device);
  }, [setDevice]);

  useEffect(() => {
    getDevice();

    window.addEventListener('resize', getDevice, false);

    return () => {
      window.removeEventListener('resize', getDevice);
    };
  }, [getDevice]);

  useEffect(() => {
    dispatch(actions.getServices());
    dispatch(actions.getSpecialists());
    dispatch(actions.getFeeds());
    dispatch(actions.getPosts());
    dispatch(actions.getFAQ());
    dispatch(actions.getNews());
    dispatch(actions.countContacts());
    dispatch(actions.getProductsList());

    dispatch(actions.getSchoolList());
    dispatch(actions.getCitiesList());
    dispatch(actions.getEventsList());
    dispatch(actions.getSchoolFeeds());
    dispatch(actions.getMetatags());
  }, [dispatch]);

  return (
    <>
      <div className={classnames('ba-app', `device-${device}`)}>
        <PwaInstallPopupIOS
          delay={3}
          lang="ru"
          appName="Beautyapple"
          appIcon="/favicon.ico"
        ></PwaInstallPopupIOS>

        <Router>
          <ScrollToTop />
          <GoogleAnalitics />

          {device !== 'desktop' && <CartBtnFloat count="5" svg="cart" />}

          <LayoutWrapper>
            <Navbar device={device} appTheme={appTheme} />
          </LayoutWrapper>

          <React.Suspense fallback={<Loading />}>
            <Switch>
              {Routers.map(({ component: Cmp, ...route }, index) => (
                <Route
                  key={index}
                  {...route}
                  render={(props) => <Cmp {...props} device={device} />}
                  history={useHistory}
                  exact
                ></Route>
              ))}
            </Switch>
          </React.Suspense>

          <LayoutWrapper>
            <Footer device={device} appTheme={appTheme} />
          </LayoutWrapper>

          <Popups device={device} />
        </Router>
      </div>
    </>
  );
};

export default App;
