export default {
  baseURL: 'https://api.beautyapple.com.ua/',
  // baseURL: process.env.REACT_APP_API_URL,
  news: {
    url: `/api/get-promos`,
    method: 'GET',
  },
  services: {
    url: `/api/services`,
    // url: `/api/services-new`,
    method: 'GET',
  },
  faq: {
    url: `/api/faq`,
    method: 'GET',
  },
  feeds: {
    url: `/api/feeds`,
    method: 'GET',
  },
  specialists: {
    url: `/api/staffs`,
    method: 'GET',
  },
  products: {
    url: `/api/products`,
    method: 'GET',
  },
  contacts: {
    url: `/api/contacts`,
    method: 'GET',
  },
  getPosts: {
    url: `/api/get-posts`,
    method: 'GET',
  },
  getSchoolThemes: {
    url: `/api/school/themes`,
    method: 'GET',
  },
  getSchoolCities: {
    url: `/api/school/cities`,
    method: 'GET',
  },
  getSchoolFeeds: {
    url: `/api/school/feeds`,
    method: 'GET',
  },
  getSchoolEvents: {
    url: `/api/school/events`,
    method: 'GET',
  },
  getMetatags: {
    url: `/api/get-microdata`,
    // url: `/mocks/metatags.js`,
    method: 'GET',
  },
  sendSchoolFeed: {
    url: `/api/school/send-feed`,
    method: 'POST',
  },
  sendSchoolRequest: {
    url: `/api/school/send-request`,
    method: 'POST',
  },
  sendForm: {
    url: '/api/send-request',
    method: 'POST',
  },
  sendFeed: {
    url: '/api/send-feed',
    method: 'POST',
  },
  sendProduct: {
    url: '/api/send-product',
    method: 'POST',
  },
  sendFAQStatistic(id) {
    return {
      url: `/api/faq-statistic/${id}`,
      method: 'POST',
    };
  },
};
