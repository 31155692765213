import email from './email';
import required from './required';
import phone from './phone';
import name from './name';

export default {
  email,
  required,
  phone,
  name,
};
