import { web } from '../constants';
import orderByAvailability from '../../helpers/orderAvailabilityProduct';
import { APP_THEMES } from '../constants/index';

const initialState = {
  servicesList: [],
  feedsList: [],
  faqList: [],
  newsList: [],
  specialists: [],
  products: [],
  brands: [],
  postsList: [],
  categories: [],
  skin_needs: [],
  usage_form: [],
  school: {
    themes: [],
    cities: [],
    events: [],
  },
  metadata: [],
  contacts: 0,
  appTheme: APP_THEMES.white,
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case web.SAVE_SERVICES_LIST:
      return {
        ...state,
        ...{
          servicesList: action.payload
            .map((item) => ({
              original: {
                fall: '/storage/services/default/list/original/New-service.jpg',
                webp: '/storage/services/default/list/original/New-service.webp',
              },
              thumb: {
                fall: '/storage/services/default/list/thumb/New-service.jpg',
                webp: '/storage/services/default/list/thumb/New-service.webp',
              },
              ...item,
              type_content: 'service',
              children: item.children.filter((i) => i.is_visible),
            }))
            .map((item) => ({
              ...item,
              original: {
                fall: process.env.REACT_APP_API_URL + item.original.fall,
                webp: process.env.REACT_APP_API_URL + item.original.webp,
              },
              thumb: {
                fall: process.env.REACT_APP_API_URL + item.thumb.fall,
                webp: process.env.REACT_APP_API_URL + item.thumb.webp,
              },
            }))
            .filter((el) => el.children.length > 0),
        },
      };
    case web.SAVE_FEEDS_LIST:
      return {
        ...state,
        ...{
          feedsList: action.payload
            .filter((i) => i.is_visible)
            .map((item) => ({
              ...item,
              created_at_orig: new Date(`${item.created_at}`),
            }))
            .sort((a, b) => b.created_at_orig - a.created_at_orig),
        },
      };
    case web.SAVE_POSTS_LIST:
      return {
        ...state,
        ...{
          postsList: action.payload
            .filter((i) => i.is_published)
            .map((i) => ({
              ...i,
              type_content: 'post',
            }))
            .sort((a, b) => b.created_at - a.created_at),
        },
      };
    case web.SAVE_NEWS_LIST:
      return {
        ...state,
        ...{
          newsList: action.payload
            .filter((i) => i.is_active)
            .map((i) => ({
              ...i,
              type_content: 'news',
            }))
            .sort((a, b) => b.created_at - a.created_at),
        },
      };
    case web.SAVE_FAQ_LIST:
      return {
        ...state,
        ...{
          faqList: action.payload
            .filter((i) => i.is_visible)
            .filter((i) => i.staff)
            .map((i) => ({
              ...i,
              type_content: 'faq',
            })),
        },
      };
    case web.SAVE_PRODUCTS_LIST:
      return {
        ...state,
        ...{
          brands: action.payload.brands_list,
          categories: action.payload.categories,
          usage_form: action.payload.usage_form,
          skin_needs: action.payload.skin_needs,
          products: orderByAvailability(
            action.payload.products_list.filter((i) => i.is_visible)
          ),
        },
      };
    case web.SAVE_SPECIALISTS_LIST:
      return {
        ...state,
        ...{
          specialists: action.payload
            .map((item) => ({
              img: 'starage/staffs/default.jpg',
              ...item,
            }))
            .map((item) => ({
              ...item,
              img: process.env.REACT_APP_API_URL + item.img,
            })),
        },
      };
    case web.SAVE_CONTACTS_COUNT:
      return {
        ...state,
        ...{
          contacts: action.payload,
        },
      };
    case web.SAVE_SCHOOL_THEMES_LIST:
      return {
        ...state,
        ...{
          school: {
            ...state.school,
            themes: action.payload,
          },
        },
      };
    case web.SAVE_SCHOOL_CITIES_LIST:
      return {
        ...state,
        ...{
          school: {
            ...state.school,
            cities: action.payload.sort((a, b) => {
              if (a.id === 8) return -1;

              if (b.id === 8) return 1;

              return 0;
            }),
          },
        },
      };
    case web.SAVE_SCHOOL_EVENTS_LIST:
      return {
        ...state,
        ...{
          school: {
            ...state.school,
            events: action.payload,
          },
        },
      };
    case web.SAVE_SCHOOL_FEEDS:
      return {
        ...state,
        school: {
          ...state.school,
          feeds: action.payload,
        },
      };
    case web.SAVE_METAGATS_LIST:
      return {
        ...state,
        metadata: action.payload,
      };
    case web.SET_APP_THEME:
      if (state.appTheme === action.payload) {
        return state;
      }

      return {
        ...state,
        appTheme: action.payload,
      };
    default:
      return state;
  }
};

export default servicesReducer;
